import { BrowserRouter, Routes, Route } from "react-router-dom";
import Congratulation from "./pages/end";
import Game from "./pages/game";
import Home from "./pages/home";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recaptcha" element={<Game />} />
          <Route path="/parabens" element={<Congratulation />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
