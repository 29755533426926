import "./index.scss";

function Image({ image, handleImageClick, selected }) {
    const hadleClick = () => {
        handleImageClick(image);
    }

    return (
        <div className="col-md-3" onClick={hadleClick}>
            <div className={"recaptcha-image " + (selected ? "selected" : "")}>
                <img src={image.src} alt="Recaptcha" width="100" height="100" />
            </div>
        </div>
    );
}

export default Image;