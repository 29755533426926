import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function Home() {
    useEffect(() => {
        document.title = "Prove que você não é um robô!";
    }, []);

    return (
        <section className="home">
            <div className="container">
                <h1>
                    Prove que você não é um robô!
                </h1>
                <Link to={{ 
                        pathname: "/recaptcha"
                    }} 
                    className="btn btn-primary"
                 >
                    Provar que sou humano
                </Link>
            </div>
        </section>
    );
}

export default Home;