import { useState, useRef, useEffect } from "react";
import "./index.scss";
import image from "../../assets/recaptcha.png";
import Recaptcha from "../../components/recaptcha";

function Game() {
    const [ timer, setTimer ] = useState(0);
    const increment = useRef(null);

    const startGame = () => {
        document.querySelector(".recaptcha").classList.toggle("show");

        increment.current = setInterval(() => {
            setTimer((timer) => timer + 1)
        }, 1)
    }

    const formatDate = function(millisec) {
        const milliseconds = millisec;
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);

        return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0") + ":" + (milliseconds % 1000).toString().padStart(3, "0");
    }

    useEffect(() => {
        document.title = `Tempo: ${formatDate(timer)}`;
    }, [ timer ]);

    return (
        <section className="game">
            <div className="container">
                <h1>
                    Você sabe reconhecer os robôs <br />
                    que estão programados e inseridos <br />
                    na vida real?
                </h1>
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-auto">
                                <input type="checkbox" name="check" id="check" onClick={startGame} />
                            </div>
                            <div className="col-md">
                                <p>
                                    Não sou um robô
                                </p>
                            </div>
                            <div className="col-md-auto">
                                <img src={image} alt="Recaptcha" width="40" height="40" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Recaptcha timer={timer} />
        </section>
    );
}

export default Game;