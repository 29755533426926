import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Image from "../image";
import "./index.scss";

const imagesList = [
    { id: 1, src: "/images/img1.jpg", verify: true },
    { id: 2, src: "/images/img2.jpg", verify: true },
    { id: 3, src: "/images/img3.jpg", verify: true },
    { id: 4, src: "/images/img4.jpg", verify: true },
    { id: 5, src: "/images/img5.jpg", verify: true },
    { id: 6, src: "/images/img6.jpg", verify: true },
    { id: 7, src: "/images/img7.jpg", verify: true },
    { id: 8, src: "/images/img8.jpg", verify: true },
    { id: 9, src: "/images/img9.jpg", verify: true },
    { id: 10, src: "/images/img10.jpg", verify: false },
    { id: 11, src: "/images/img11.jpg", verify: false },
    { id: 12, src: "/images/img12.jpg", verify: false },
    { id: 13, src: "/images/img13.jpg", verify: false },
    { id: 14, src: "/images/img14.jpg", verify: false },
    { id: 15, src: "/images/img15.jpg", verify: false },
    { id: 16, src: "/images/img16.jpg", verify: false },
    { id: 17, src: "/images/img17.jpg", verify: false },
    { id: 18, src: "/images/img18.jpg", verify: false },
];

function Recaptcha({ timer }) {
    const [ images, setImages ] = useState([]);
    const [ choiceOne, setChoiceOne ] = useState(null);
    const [ choiceTwo, setChoiceTwo ] = useState(null);
    const [ choiceThree, setChoiceThree ] = useState(null);
    const [ choiceFour, setChoiceFour ] = useState(null);
    const navigate = useNavigate();

    const formatDate = function(millisec) {
        const milliseconds = millisec;
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);

        return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0") + ":" + (milliseconds % 1000).toString().padStart(3, "0");
    }

    const shuffle = () => {
        const shuffledImages = [ 
            ...imagesList.sort(() => 0.5 - Math.random()).filter(image => image.verify).slice(0, 4), 
            ...imagesList.sort(() => 0.5 - Math.random()).filter(image => image.verify === false).slice(0, 8) 
        ];

        setImages(shuffledImages.sort(() => 0.5 - Math.random()));
    }

    const handleImageClick = (image) => {
        if (choiceOne === null) {
            setChoiceOne(image);
        } else if (choiceTwo === null) {
            if (choiceOne !== image) {
                setChoiceTwo(image);
            } else {
                setChoiceOne(null);
            }
        } else if (choiceThree === null) {
            if (choiceOne !== image && choiceTwo !== image) {
                setChoiceThree(image);
            } else {
                setChoiceTwo(null);
            }
        } else if (choiceFour === null) {
            if (choiceOne !== image && choiceTwo !== image && choiceThree !== image) {
                setChoiceFour(image);
            } else {
                setChoiceThree(null);
            }
        }
    }

    useEffect(() => {
        if (choiceOne && choiceTwo && choiceThree && choiceFour) {
            if (choiceOne.verify && choiceTwo.verify && choiceThree.verify && choiceFour.verify) {
                Swal.fire({
                    title: "Parabéns!",
                    text: "Você acertou todas as imagens!",
                    icon: "success",
                    confirmButtonText: "Prosseguir",
                    allowOutsideClick: false,
                }).then(() => {
                    let cd = Math.floor(new Date().getTime() / Math.random()).toString(20);

                    const data = {
                        timer: formatDate(timer),
                        code: cd
                    };

                    fetch("https://base.aprovajob.com/api/codes/create", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    }).then(() => {
                        navigate("/parabens",
                            {
                                state: {
                                    timer: formatDate(timer),
                                    code: cd
                                }
                            }
                        );
                    });
                });
            } else {
                Swal.fire({
                    title: "Imagens incorretas!",
                    text: "Tente novamente!",
                    icon: "error",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                }).then(() => {
                    reset();
                });
            }
        }
    }, [ choiceOne, choiceTwo, choiceThree, choiceFour ]);


    const reset = () => {
        setChoiceOne(null);
        setChoiceTwo(null);
        setChoiceThree(null);
        setChoiceFour(null);
        shuffle();
    }

    // Automation
    useEffect(() => {
        shuffle();
    }, []);

    return (
        <div className="recaptcha">
            <p>
                Selecione 4 imagens que realmente representam os robôs na vida real.
            </p>
            <div className="row g-0">
                {images.map(image => (
                    <Image 
                        key={image.id} 
                        image={image}
                        handleImageClick={handleImageClick} 
                        selected={image === choiceOne || image === choiceTwo || image === choiceThree || image === choiceFour}
                    />
                ))}
            </div>
            <div className="text-center">
                <p className="mt-3 mb-0">
                    Tempo: { formatDate(timer) }
                </p>
            </div>
        </div>
    );
}

export default Recaptcha;