import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import image from "../../assets/icon-printscreen.png";
import "./index.scss";

function Congratulation() {
    const { state } = useLocation();
    const { timer, code } = state;

    useEffect(() => {
        document.title = "Parabéns!";
    });

    return (
        <section className="congratulations">
            <div className="container">
                <p>
                    Você acertou todas as imagens com um tempo de <b>{timer}</b> segundos!
                </p>
                <h1>
                    Torça para que outro humano não seja tão rápido para você garantir
                    <br />
                    <span>
                        um combo da @PizzariadaRita (unidade Mangal)
                    </span>
                </h1>
                <div className="code">
                    {code}
                    <img src={image} alt="Printscreen" />
                </div>
                <small>
                    Tire um print do seu código e acompanhe o resultado em nosso instagram @kombiagencia <br />
                    O resultado sai hoje às 22h00*
                </small>
                <br />
                <br />
                <a href="https://base.aprovajob.com/ranking" className="btn btn-primary">
                    Ranking
                </a>
            </div>
        </section>
    );
}

export default Congratulation;